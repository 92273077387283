import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Invazní procesy ve světě a ČR" />
    <h1>Invazní procesy ve světě a ČR</h1>

    <p>Pohled na invazivn&iacute; procesy, tedy zavlečen&iacute; nepůvodn&iacute;ho druhu, jeho naturalizace a pot&eacute; invaze, ve světě (a potažmo tak i ČR) a jejich patrnost&iacute; jsou souč&aacute;st&iacute; <Link to="/biogeografie/invazni-ekologie/">invazn&iacute; ekologie</Link>.</p>
    <p>Mezi nejv&iacute;ce invazivn&iacute; druhy, podle toho, v kolika regionech světa se chovaj&iacute; invazivně, patř&iacute; lant&aacute;na (<em>Lantana camara</em>), plchoplod otevřen&yacute; (sodomsk&eacute; jablko, <em>Calotropis procera</em>) či tokozelka nadmut&aacute; (<em>Eichhornia crassipes</em>).</p>
<hr />
    <h2>Z&aacute;kladn&iacute; patrnosti rostlinn&yacute;ch invaz&iacute; ve světě</h2>
    <p>Mezi z&aacute;kladn&iacute; patrnosti rostlinn&yacute;ch invaz&iacute; ve světě patř&iacute; tyto vybran&eacute; poznatky:</p>
    <ul>
    <li><strong>Vět&scaron;&iacute; invadovanost ostrovů než pevniny</strong></li>
    <li><strong>Nov&yacute; svět je invadov&aacute;n v&iacute;ce než Star&yacute; svět</strong></li>
    <li><strong>Temper&aacute;tn&iacute; a bore&aacute;ln&iacute; z&oacute;na je invadov&aacute;na v&iacute;ce než tropy</strong></li>
    <li><strong>N&iacute;žiny jsou v&iacute;ce invadov&aacute;ny než horsk&eacute; oblasti</strong></li>
    </ul>
    <h3>Vět&scaron;&iacute; invadovanost ostrovů</h3>
    <p>Vět&scaron;&iacute; invadovanost ostrovů než pevniny vypl&yacute;v&aacute; z men&scaron;&iacute;ho počtu druhů na ostrovech a t&iacute;m p&aacute;dem i vy&scaron;&scaron;&iacute; dostupnosti voln&yacute;ch nik. Někter&eacute; z nich jsou pak vhodn&eacute; pro os&iacute;dlen&iacute; nepůvodn&iacute;mi druhy.</p>
    <p>Nav&iacute;c, nepůvodn&iacute; druhy zavlečen&eacute; na ostrovy jsou vyb&iacute;r&aacute;ny z obrovsk&yacute;ch souborů odjinud. Je pak pravděpodobn&eacute;, že jsou v těchto souborech konkurenčně silněj&scaron;&iacute; druhy, než jak&eacute; se vyvinuly v omezen&yacute;ch souborech druhů na ostrově.</p>
    <h3>Nov&yacute; svět je invadov&aacute;n v&iacute;ce než Star&yacute;</h3>
    <p>Zavl&eacute;k&aacute;n&iacute; rostlin se z&aacute;mořskou kolonizaci prob&iacute;halo asymetricky a Nov&yacute; svět byl (a je) invadov&aacute;n v&iacute;ce než Star&yacute; svět. Jednou z př&iacute;čin je vět&scaron;&iacute; selekce adaptace na soužit&iacute; s člověkem u druhů Star&eacute;ho světa. Druhy Star&eacute;ho světa pak tak&eacute; v minulosti v&iacute;ce migrovaly a l&eacute;pe se adaptoval na konkurenci s mnoha jin&yacute;mi druhy.</p>
    <h3>Temper&aacute;tn&iacute; a bore&aacute;ln&iacute; z&oacute;na je invadov&aacute;na v&iacute;ce než tropy</h3>
    <p>Vy&scaron;&scaron;&iacute; invadovanost temper&aacute;tn&iacute; a bore&aacute;ln&iacute; z&oacute;ny než tropů souvis&iacute; s faktem, že intenzita z&aacute;mořsk&eacute;ho obchodu mezi mimotropick&yacute;mi oblastmi je vy&scaron;&scaron;&iacute;. Nav&iacute;c, velk&aacute; produktivita a rychl&aacute; obnova tropick&eacute; vegetace po př&iacute;padn&eacute; disturbanci omezuje možnost invaze.</p>
    <p>Tropick&eacute; ostrovy jsou v&scaron;ak invadov&aacute;ny podobně jako v&scaron;echny ostatn&iacute;, fenom&eacute;n ostrova je v př&iacute;padě invazn&iacute;ch procesů silněj&scaron;&iacute; než fenom&eacute;n tropů.</p>
    <h3>N&iacute;žiny jsou v&iacute;ce invadov&aacute;ny než hory</h3>
    <p>Horsk&eacute; oblasti jsou m&eacute;ně invadov&aacute;ny než n&iacute;žiny, a to d&iacute;ky men&scaron;&iacute;mu počtu antropogenn&iacute;ch disturbanc&iacute;, men&scaron;&iacute;mu zavl&eacute;k&aacute;n&iacute; diaspor a omezen&eacute; konektivitě horsk&eacute; krajiny.</p>
    <p>Nav&iacute;c, pro druhy n&iacute;žin je obt&iacute;žn&eacute; se adaptovat na horsk&eacute; podm&iacute;nky a naopak pro druhy horsk&yacute;ch oblast&iacute; se n&iacute;žiny chovaj&iacute; jako migračn&iacute; filtr, přes kter&yacute; se nedostanou.</p>
<hr />
    <h2>Př&iacute;klady zn&aacute;m&yacute;ch invaz&iacute; v Severn&iacute; Americe, Evropě a Austr&aacute;lii</h2>
    <p>N&iacute;že jsou velmi stručně uvedeny př&iacute;klady nejzn&aacute;měj&scaron;&iacute;ch světov&yacute;ch invaz&iacute;, kter&eacute; jsou ve v&scaron;ech př&iacute;padech živoči&scaron;n&eacute;. Mezi uk&aacute;zku invaze v <strong>Severn&iacute; Americe</strong> patři:</p>
    <ul>
    <li><strong>&scaron;paček obecn&yacute;</strong> (<em>Sturnus vulgaris</em> zavlečen na v&yacute;chodn&iacute; pobřež&iacute; USA v roce 1896, roku 1980 dos&aacute;hl z&aacute;padn&iacute;ho pobřež&iacute; a v roce 1990 se vyskytoval již na vět&scaron;ině &uacute;zem&iacute; Kanady)</li>
    <li><strong>vrabec dom&aacute;c&iacute;</strong> (<em>Passer domesticus</em> zavlečen roku 1852 na v&yacute;chodn&iacute; pobřež&iacute; USA a později na dal&scaron;&iacute; m&iacute;sta, v roce 1900 se již vyskytoval prakticky na cel&eacute;m &uacute;zem&iacute; kontinent&aacute;ln&iacute;ch USA)</li>
    </ul>
    <p>Nejzn&aacute;měj&scaron;&iacute;mi živoči&scaron;n&yacute;mi invazemi v <strong>Evropě</strong> jsou patrně tyto dvě:</p>
    <ul>
    <li><strong>ondatra pižmov&aacute;</strong> (<em>Ondatra zibethicus </em>byla introdukov&aacute;na roku 1905 v Čech&aacute;ch, v roce 1957 byla již roz&scaron;&iacute;řena až k pobřež&iacute; Severn&iacute;ho moře, v Rakousku, Uhr&aacute;ch a jinde)</li>
    <li><strong>mandelinka bramborov&aacute;</strong> (<em>Leptinotarsa decemlineata</em> byla zanesena na z&aacute;padn&iacute; francouzsk&eacute; pobřež&iacute; v roce 1921, v roce 1960 již byla př&iacute;tomn&aacute; na cel&eacute;m Pyrenejsk&eacute;m poloostrově, &uacute;zem&iacute; ČSSR a v z&aacute;padn&iacute; č&aacute;sti SSSR)</li>
    </ul>
    <p>Patrně nejzn&aacute;měj&scaron;&iacute; je biologick&yacute;mi invazemi zn&aacute;m&aacute; <strong>Austr&aacute;lie</strong>, jmenujme si v&scaron;ak jen dvě z těch nejv&yacute;znamněj&scaron;&iacute;ch:</p>
    <ul>
    <li><strong>zaj&iacute;c poln&iacute;</strong> (<em>Lepus europaeus</em> byl roku 1860 a v dal&scaron;&iacute;ch letech vysazen na několika m&iacute;stech Austr&aacute;lie, v roce 1980 se pak vyskytoval na prakticky cel&eacute;m kontinentu)</li>
    <li><strong>li&scaron;ka obecn&aacute; </strong>(<em>Vulpes vulpes</em> je hlavn&iacute; př&iacute;činou extinkc&iacute; drobn&yacute;ch savců v Austr&aacute;lii, li&scaron;ka se objevila v Austr&aacute;lii kolem roku 1880 a v roce 2000 se již vyskytovala prakticky na cel&eacute;m kontinentu)</li>
    </ul>
<hr />
    <h2>Česk&aacute; republika a invaze</h2>
    <p>Podle v&yacute;zkumu z roku 2012 (Pry&scaron;ek) existuje v Česk&eacute; republice 62 invazn&iacute;ch a 407 naturalizovan&yacute;ch druhů. Nejv&iacute;ce nepůvodn&iacute;ch druhů poch&aacute;z&iacute; z Mediter&aacute;nn&iacute;ho regionu, ve vět&scaron;ině př&iacute;padů jde o archeofyty.</p>
    <p>Nejv&iacute;ce jsou postiženy invazemi stanovi&scaron;tě v n&iacute;žin&aacute;ch pod&eacute;l velk&yacute;ch řek, zejm&eacute;na Labe. To plat&iacute; zejm&eacute;na o invaz&iacute; druhů typů black&amp;grey, tedy nejhor&scaron;&iacute;ch invazn&iacute;ch druhů ČR nach&aacute;zej&iacute;c&iacute;ch se na blacklistu a greylistu.</p>
    <h3>Invadovanost biotopů v lok&aacute;ln&iacute;m měř&iacute;tku</h3>
    <h4>Vy&scaron;&scaron;&iacute; &scaron;ance rostlinn&eacute; invaze</h4>
    <p>Mezi hodně invadovan&eacute; biotopy patř&iacute;:</p>
    <ul>
    <li>orn&aacute; půda</li>
    <li>pobřežn&iacute; duny</li>
    <li>se&scaron;lap&aacute;van&eacute; plochy</li>
    <li>ruder&aacute;ln&iacute; bylinn&aacute; vegetace</li>
    <li>r&aacute;kosiny a ostřicov&eacute; porosty</li>
    </ul>
    <p>Obecně se d&aacute; oček&aacute;vat vysok&aacute; invazibilita tam, kde jsou:</p>
    <ul>
    <li>živinami bohat&eacute; půdy</li>
    <li>čast&eacute; a siln&eacute; disturbance</li>
    <li>fluktuace dostupnosti limituj&iacute;c&iacute;ch zdrojů</li>
    </ul>
    <h4>Niž&scaron;&iacute; &scaron;ance rostlinn&eacute; invaze</h4>
    <p>Mezi m&aacute;lo invadovan&eacute; biotopy se řad&iacute;:</p>
    <ul>
    <li>ra&scaron;elini&scaron;tě</li>
    <li>alp&iacute;nsk&aacute; a subalp&iacute;nsk&aacute; vegetace</li>
    <li>vřesovi&scaron;tě na chud&yacute;ch půd&aacute;ch</li>
    </ul>
    <p>Mal&aacute; invazibilita b&yacute;v&aacute; obecně v m&iacute;stech, kde jsou:</p>
    <ul>
    <li>živinami chud&eacute; půdy</li>
    <li>mal&eacute; disturbance</li>
    <li>dostupnosti limituj&iacute;c&iacute;ch zdrojů vyrovnan&eacute; v čase</li>
    </ul>
    <h3>Př&iacute;klady invazn&iacute;ch rostlin ČR</h3>
    <p>Z 62 invazivn&iacute;ch rostlin Česka, z nich naprost&aacute; vět&scaron;ina jsou neofyty, jsou těmi nejtypičtěj&scaron;&iacute;mi př&iacute;klady tyto invazn&iacute; druhy:</p>
    <ul>
    <li><strong>net&yacute;kavka malokvět&aacute;</strong> (<em>Impatiens parviflora</em>, Asie, introdukce 1870)</li>
    <li><strong>net&yacute;kavka žl&aacute;znat&aacute;</strong> (<em>Impatiens glandulifera</em>, Asie, zavlečen&iacute; 1896)</li>
    <li><strong>kř&iacute;dlatka japonsk&aacute;</strong> (<em>Reynoutria japonica</em>, Asie, zavlečen&iacute; 1892)</li>
    <li><strong>zlatob&yacute;l kanadsk&yacute;</strong> (<em>Solidago canadensis</em>, Severn&iacute; Amerika, 1838)</li>
    <li><strong>bol&scaron;evn&iacute;k velkolep&yacute; </strong>(<em>Heracieum mantegazzianum</em>, Evropa, introdukov&aacute;no 1862)</li>
    <li><strong>peťour malokvět&yacute;</strong> (<em>Galinsoga parviflora</em>, Jižn&iacute; Amerika, zavlečeno 1880)</li>
    <li><strong>lupina mnoholist&aacute;</strong> (<em>Lupinus polyphyllus</em>, Severn&iacute; Amerika, 1895)</li>
    <li><strong>slunečnice topinambur</strong> (<em>Helianthus tuberosus</em>, Severn&iacute; Amerika, 1885)</li>
    </ul>

    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Invazní procesy ve světě a ČR</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/invazni-ekologie/invaze-zavlecene-druhy-a-dalsi-terminologie/"><button className="inv">&larr; Invaze, zavlečené druhy...</button></Link>
    <Link to="/biogeografie/invazni-ekologie/faktory-invazivnosti-a-invazibility/"><button className="inv">Faktory invazivnosti a invazibility &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
